import React, { useRef,useEffect,useState } from 'react'
import {useFrame, useThree} from '@react-three/fiber'
import * as THREE from 'three'
import { useScroll,useGLTF,PerspectiveCamera,Decal,MeshTransmissionMaterial,RenderTexture,Text,Edges} from '@react-three/drei'
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
var loadedstate=false


export default function Mdos(props) {
    const scroll = useScroll()
    const group = useRef()
    const mesh1 = useRef()
    const mesh2= useRef()
    const { scene, nodes, materials } = useGLTF('models/mix3.glb')
    const { width, height } = useThree((state) => state.viewport)

    const [showModel, setshowModel] = useState(false);


    

 
    /*

    const { autoRotate, text, shadow, ...config } = useControls({
        text: 'Inter',
        backside: false,
        samples: { value: 1, min: 1, max: 32, step: 1 },
        resolution: { value: 64, min: 64, max: 2048, step: 64 },
        transmission: { value: 0.6, min: 0, max: 1 },
        clearcoat: { value: 0, min: 0.1, max: 1 },
        clearcoatRoughness: { value: 0.0, min: 0, max: 1 },
        thickness: { value: 0.55, min: 0, max: 5 },
        chromaticAberration: { value: 5, min: 0, max: 5 },
        anisotropy: { value: 0.3, min: 0, max: 1, step: 0.01 },
        roughness: { value: 0.0, min: 0, max: 1, step: 0.01 },
        distortion: { value: .4, min: 0, max: 4, step: 0.01 },
        distortionScale: { value: 1, min: 0.01, max: 1, step: 0.01 },
        temporalDistortion: { value: 0.4, min: 0, max: 1, step: 0.01 },
        ior: { value: 0.83, min: 0, max: 2, step: 0.01 },
        color: '#ff9cf5',
        gColor: '#ff7eb3',
        shadow: '#750d57',
        autoRotate: false,
        screenshot: button(() => {
          // Save the canvas as a *.png
          const link = document.createElement('a')
          link.setAttribute('download', 'canvas.png')
          link.setAttribute('href', document.querySelector('canvas').toDataURL('image/png').replace('image/png', 'image/octet-stream'))
          link.click()
        })
      })
      
      */



    scene.traverse( function( object ) {

        if ( object.isMesh ) {

            object.material.color.set( 0x999 );
            object.material.transparent = true;
            object.material.opacity = 1;
            object.material.roughness=0.6
            object.material.metalness=0.25
            object.material.emissive=new THREE.Color(0x000000)
           // object.material.color=new THREE.Color(0x666)

        }

    } );



    useFrame((state, delta) => {

        if(props.Loadedin==1){
           // console.log('show model')
            setshowModel(true)
          
        }

        const r1 = scroll.range(0 / 4, 1 / 4)
        const r2 = scroll.range(1 / 50, 1 / 12)
        const r3 = scroll.visible(4 / 5, 1 / 5)
        const r4 = scroll.range(4 / 50, 1 / 6)
      

        

        // console.log(r5)
        
        var setScorp=50-r4*50
        if(setScorp<10)setScorp=10

     if(!isMobile)setScorp=50

       // console.log(setScorp)
        group.current.position.z=0

        const et = state.clock.elapsedTime
        group.current.position.y = Math.sin((et +   2) / 2) * .01
        group.current.rotation.x = Math.sin((et +   2) / 3) / 10
        group.current.rotation.y = Math.sin((et +   2) / 3) / 2+r2*2
        group.current.rotation.z = Math.sin((et +   2) / 3) / 10 

        group.current.position.x = THREE.MathUtils.damp(group.current.position.x, (-width/setScorp / 7) * r2, 4, .1)
        group.current.position.z = THREE.MathUtils.damp(group.current.position.z, 10 * r2, 4, .1)

        mesh1.current.scale.x=.0012
        mesh1.current.scale.y=.0012
        mesh1.current.scale.z=.0012
        mesh1.current.rotation.set(-1.5,0,0)
       
        mesh2.current.scale.x=.52
        mesh2.current.scale.y=.52
        mesh2.current.scale.z=.52
        mesh2.current.position.y=.007
        mesh2.current.position.x=-.004
        mesh2.current.rotation.set(1,0,0)

        const t = state.clock.getElapsedTime()
        var ty=.2
       // group.current.rotation.x = -Math.PI / 1.75 + Math.cos(t / 4) / 8*ty
       // group.current.rotation.y = Math.sin(t / 4) / 8*ty
       // group.current.rotation.z = (1 + Math.sin(t / 1.5)) / 20*ty/2
       // group.current.position.y = (1 + Math.sin(t / 1.5)) / 10*ty/2


       /*

        const sin = Math.sin(state.clock.elapsedTime / 2)
        const cos = Math.cos(state.clock.elapsedTime / 2)
        ref.current.layers[0].origin.set(cos / 2, 0, 0)
        ref.current.layers[1].origin.set(cos, sin, cos)
        ref.current.layers[2].origin.set(sin, cos, sin)
        ref.current.layers[3].origin.set(cos, sin, cos)


       */

   // <primitive ref={group} object={scene} />

    })
    //console.log(nodes)

    return <group {...props}  ref={group}   > 
            
       
      {/*
            <Caustics color="pink" lightSource={[5, 5, -10]} worldRadius={0.6} ior={1.2} intensity={0.2}>
            </Caustics>
             */}  
            <mesh ref={mesh1}    geometry={nodes.SK01.geometry}  visible={showModel} >
            
                {/*<meshStandardMaterial  color="#333333"  roughness={0.3} metalness={0}/>
                
                <MeshTransmissionMaterial color="grey" resolution={32} thickness={0.5} anisotropy={2} transparent={true} opacity={1} temporalDistortion={0.1} distortion={10} />
                
                */}

                <meshPhysicalMaterial color="black" resolution={1} thickness={0.5} anisotropy={1} transparent={true} opacity={1} temporalDistortion={0.1} distortion={1} transmission={.3} ior={1.25} envMapIntensity={1}  roughness={0} clearcoat={1} clearcoatRoughness={0}  />
                
                
            </mesh>


      
      
           

            {/*
       <mesh  scale={100}  >
            <sphereGeometry args={[1, 64, 64]} />
      
            <MeshTransmissionMaterial color="grey" reflectivity={0.5} {...config}  side={THREE.BackSide} background={texture}/>
            
            </mesh>

 castShadow receiveShadow
<MeshTransmissionMaterial color="grey" resolution={32} thickness={0.5} anisotropy={2}  temporalDistortion={0.1} distortion={10}  />
      */} 
            
             
             <mesh ref={mesh2}   geometry={nodes.SK02.geometry}  visible={showModel}>
             
             <meshPhysicalMaterial color="black" resolution={1} thickness={0.5} anisotropy={1} transparent={true} opacity={1} temporalDistortion={0.1} distortion={10} transmission={.3} ior={1.25} envMapIntensity={1}  roughness={0} clearcoat={1} clearcoatRoughness={0}  />

             <Decal position={[0, 0, 0]} rotation={0} scale={0.2}> 
                <meshBasicMaterial transparent opacity={1} polygonOffset polygonOffsetFactor={-100}   color={[5, 0.5, 0]} side={THREE.DoubleSide} toneMapped={false} >
                <TickerTexture  ShowModel={showModel}/>
                </meshBasicMaterial>
             </Decal>
            
           {/*



        <LayerMaterial ref={ref} toneMapped={false}>
        <Depth colorA="#ff0080" colorB="black" alpha={1} mode="normal" near={0.5 * gradient} far={0.5} origin={[0, 0, 0]} />
        <Depth colorA="blue" colorB="#f7b955" alpha={1} mode="add" near={2 * gradient} far={2} origin={[0, 1, 1]} />
        <Depth colorA="green" colorB="#f7b955" alpha={1} mode="add" near={3 * gradient} far={3} origin={[0, 1, -1]} />
        <Depth colorA="white" colorB="red" alpha={1} mode="overlay" near={1.5 * gradient} far={1.5} origin={[1, -1, -1]} />
        <Fresnel mode="add" color="white" intensity={0.5} power={1.5} bias={0.05} />
      </LayerMaterial>
           
              
             */}
            </mesh>
          
            </group>
           

     




}


function TickerTexture(props) {
    const textRef = useRef()
    useEffect(() => {
      let count = 0
      if(props.ShowModel==true){
      const interval = setInterval(() => {
        if (++count > 99) count = 0
        textRef.current.text = `${count}-)`
        textRef.current.sync()
      }, 100)
      return () => clearInterval(interval)
    }
    })
    return (
      <RenderTexture attach="map" anisotropy={5} samples={1}>
        <PerspectiveCamera makeDefault manual aspect={.4 / 2} position={[1.5, 0, 5]} />
        
        <Text anchorX="right"   rotation={[0, Math.PI, 0]} ref={textRef} fontSize={1.5} />
      </RenderTexture>
    )
  }

useGLTF.preload('models/mix3.glb')