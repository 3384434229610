
import React, {Fragment, Suspense, useState, useCallback, useEffect, useRef, useMemo ,useLayoutEffect} from 'react'
import { RGBELoader } from 'three-stdlib'
import { Canvas, useFrame, useThree,useLoader } from '@react-three/fiber'
import * as THREE from 'three'
import { Stats,useIntersect, Image, ScrollControls, Scroll ,Loader,useScroll,
    useGLTF,OrbitControls ,PerspectiveCamera,useProgress,Html,Decal,Lightformer,
    Caustics,MeshTransmissionMaterial,Environment,RandomizedLight,AccumulativeShadows,RenderTexture,Text,Edges} from '@react-three/drei'

import Mdos from './Mdos'
import { EffectComposer, Bloom ,DepthOfField,Noise,ChromaticAberration} from '@react-three/postprocessing'
import { BlendFunction } from 'postprocessing'
import { LayerMaterial, Base, Depth,Fresnel } from 'lamina'
import { useControls, button } from 'leva'
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

import Wx from 'weixin-js-sdk' 

import './Home.css';


var loadedstate=false
var columnstate=0
var scrollinfo=[]

 var hostloca='/'
//var hostloca='https://www.irabit.com/'
//var hostloca='https://cdn.irabit.com/irabit/public/'
//var hostloca='http://192.168.31.22:3000/'


//import { ScrollControls, Scroll, useScroll } from './ScrollControls'


function Box(props) {
    // This reference will give us direct access to the mesh
    const mesh = useRef()
    // Set up state for the hovered and active state
    const [hovered, setHover] = useState(false)
    const [active, setActive] = useState(false)
    // Subscribe this component to the render-loop, rotate the mesh every frame
    useFrame((state, delta) => (mesh.current.rotation.x += 0.01))
    // Return view, these are regular three.js elements expressed in JSX
    return (
        <mesh
            {...props}
            ref={mesh}
            scale={active ? 1.5 : 1}
            onClick={(event) => setActive(!active)}
            onPointerOver={(event) => setHover(true)}
            onPointerOut={(event) => setHover(false)}>
            <boxGeometry args={[1, 1, 1]} />
            <meshStandardMaterial color={hovered ? 'hotpink' : 'orange'} />
        </mesh>
    )
}

 


function Item({ url, scale,id, ...props }) {
   
    const visible = useRef(false)
    const scroll = useScroll()
    const [hovered, hover] = useState(false)
    const group = useRef()
    const ref = useIntersect((isVisible) => (visible.current = isVisible))
    const { height } = useThree((state) => state.viewport)
    const { size, viewport,gl, camera } = useThree()
    const { width } = useThree((state) => state.viewport)
    //const w = width < 10 ? 1.5 / 3 : 1 / 3
    const { width: w, height: h } = useThree((state) => state.viewport)
    var lx=[0,0,0]
     var sizescale=[h *9/ 16, h, 1]
     var posZ=1
     var posX=0
     var posY=0
     if(id>8 && id<=11){
      posZ=20

      sizescale=[h *9/ 16/1.12, h/1.12, 1]
      if(!isMobile){
        posY=+.83*(id-8)-6
      }else{
        posY=+.83*(id-8)-6
      }
      if(!isMobile)posX=-.22

     }else if(id>11 && id<=15){
       //posZ=posZ*(id-10)*2

     //  if(posZ>780)posZ=780
       //sizescale=[h *9/ 16/((id-10)*1.2), h/((id-10)*1.2), 1]
      // posX=(Math.random()*4-2)*(id-10)/2
      //posY=(id-10)*6
      // posY=(Math.random()*2)*(id-10)*2


      posZ=20

      sizescale=[h *9/ 16/1.12, h/1.12, 1]
      if(!isMobile){
        posY=+.83*(id-11)-7
      }else{
        posY=+.83*(id-11)-7
      }
      if(!isMobile)posX=-.22

     } else if(id>15){
   

     posZ=20

     sizescale=[h *9/ 16/1.12, h/1.12, 1]
     if(!isMobile){
       posY=+.83*(id-15)-8
     }else{
       posY=+.83*(id-15)-8
     }
     if(!isMobile)posX=-.22

    } 
 
     
    useFrame((state, delta) => {
      const r1 = scroll.range(0 / 4, 1 / 4)
      const r2 = scroll.range(1 / 10, 1 / 12)
      const r3 = scroll.visible(4 / 5, 1 / 5)
      const r4 = scroll.range(2 /19, 1 / 8)
 
      const r5 = scroll.range(0 / 200, 1 / 1)
      
        
        var setScorp=r4
        if(!isMobile)setScorp=-1

        const et = state.clock.elapsedTime
        
        
        var mks=Math.random()*2
        if(r5<0.15)columnstate=0
        if(r5>0.15 && r5<0.95)columnstate=1
        if(r5>0.95)columnstate=2
        
        
       


        group.current.position.x =  r2*1-setScorp
       // console.log(setScorp)
       // group.current.position.z = THREE.MathUtils.damp(group.current.position.z, visible.current ? 0 : 12 - 0.8, 4, delta)
       group.current.position.z =-200
      // group.current.position.x =1.2
        ref.current.rotation.x= THREE.MathUtils.damp(ref.current.rotation.x, visible.current ? 0 : 3, 2.5, delta)
        ref.current.rotation.y= THREE.MathUtils.damp(ref.current.rotation.y, visible.current ? 0 : 2.6, 2, delta)
        


        

        ref.current.position.z =THREE.MathUtils.damp(ref.current.position.z, visible.current ? 1*posZ : -400*r1, 4, delta)
        
        //ref.current.position.z =props.position[2]
        ref.current.position.x =THREE.MathUtils.damp(ref.current.position.x, visible.current ? posX :3+posX, 2, delta)

         ref.current.position.y = THREE.MathUtils.damp(ref.current.position.y, visible.current ? posY :posY, 2, delta)
        
        ref.current.material.zoom = THREE.MathUtils.damp(ref.current.material.zoom, visible.current ? 1 : 30, 5, delta)

        if(id>4){
         ref.current.material.opacity = 1-ref.current.rotation.y/1.5
       
         ref.current.material.transparent=true
        }
       // ref.current.material.grayscale = THREE.MathUtils.damp(ref.current.material.grayscale, visible.current ? 0 : 2, 1, delta)
        
        
        

         lx=[ref.current.position.x+100,ref.current.position.y,200]
      
    })
    return (
        <group {...props} ref={group}>
            <Image ref={ref} onPointerOver={() => hover(true)} onPointerOut={() => hover(false)} scale={sizescale} url={url} toneMapped={false} transparent={false}/>

            
        </group>
    )
}


function Items(props) {
    const { width: w, height: h } = useThree((state) => state.viewport)
    const [ImgjsonData, setJsonData] = useState([]);
    const [Imgpos, setpos] = useState([]);
    const scroll = useScroll()
    const [efftercount, setefftercount] = useState(.01);
    const scalelist=[h *9/ 16, h, 1]
    var totalconst=58
    var startpointx=0,startpointy=-h*.2,startpointz=0
    //startpointy=h / 3/2

 
    useFrame((state, delta) => {
      const r2 = scroll.range(1 / 8, 1 / 8)
      const r1 = scroll.range(0 / 4, 1 / 20)
      props.callback([columnstate,scroll.delta,r1])
     scrollinfo=[columnstate,scroll.delta,r1]

     var kks=Math.floor(scroll.delta*10 * 100) / 100
     if(columnstate<1){
      if(kks>0 ){
        //setefftercount(kks-0.01)
      }
      var eff=0.01
      if(window.innerWidth>600)eff=0.004
      setefftercount(eff)
    }else{
       setefftercount(0)
    }

     
    
    })
   
      useEffect(() => {
        const url = hostloca+"data/tumbdata.json";

        const fetchData = async () => {
          try {
            const response = await fetch(url);
            const json = await response.json();
            const {results} = json;
            // Only put the results in state, ie, the actual users array
            
           
            setJsonData(json.imagedata)

            console.log(json.imagedata)
            
            var imgposList=[]
            for (let i = 0; i < json.imagedata.length; i++) {
  
                var tempx=0,tempy=startpointy-scalelist[1]*i-14,tempz=100+i*-40
                imgposList.push([tempx,tempy,tempz])
        
            }

            setpos(imgposList)
           

          } catch (error) {
            console.log("error", error);
          }
        };
    
          fetchData();
        
        
      }, []);
      
 

      
    return (
        <Scroll>

           < EffectComposer>
            <ChromaticAberration   offset={[0,efftercount]} /> 
                 <Bloom luminanceThreshold={1} intensity={6} levels={9} mipmapBlur />
             </EffectComposer>
            

            {ImgjsonData.map((props, index) => (
                <Item url={hostloca+ImgjsonData[index]} key={index} id={index} scale={scalelist} position={Imgpos[index]} rotation={[0,0,0]}   />
            ))}


        </Scroll>
    )
}



function Bg() {
    return (
      <mesh scale={100}>
        <boxGeometry args={[1, 1, 1]} />
        <LayerMaterial side={THREE.BackSide}>
          <Depth colorB="red" colorA="skyblue" alpha={1} mode="normal" near={130} far={200} origin={[100, 100, -100]} />
          <Noise mapping="local" type="white" scale={0} colorA="white" colorB="black" mode="subtract" alpha={0.2} />
        </LayerMaterial>
      </mesh>
    )
  }
  

 
/**
 * 
 */

function NewLoader(props) {
    const { active, progress, errors, item, loaded, total } = useProgress()
   

    var Nprogress=0
   

    if(Nprogress==0 && active==true)loadedstate=true
    if(loadedstate)Nprogress=parseInt(progress)
    if(Nprogress>=0  && loadedstate==true){
        console.log('loading finish')
        
         props.callback()
    }

    console.log(active+'/'+total+'int '+loaded+'progress:'+Nprogress)

    return <Html center>
        <div style={{backgroundColor:"black",width:'100vw',height:'100vh',verticalAlign:"center",display:'flex',alignItems:"center"}}>
        <div style={{color:"#fff",fontSize:"10px",width:'100vw',textAlign:'center'}}>
        
        {Nprogress} % loaded
        </div>
        </div>
        </Html>
  }


/**
 * Three js scene
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
 

function Scene(props) {
    const [contenttextAlpha, setcontenttextAlpha] = useState(0);
    const [blockcontenttextAlpha, setblockcontenttextAlpha] = useState(1);
    const [loadfinish, setloadfinish] = useState(0);
    const [scrollspeed, setscrollspeed] = useState(0.0);
    const [efftercount, setefftercount] = useState(0.0);
    const [subtxtleft, setsubtxtleft] = useState(10);
    const [artcodelab, setartcodelab] = useState(0);
    const [endlefttext, setendlefttext] = useState(0);
    const isMobile=false
    //const texture = useLoader(RGBELoader, 'http://192.168.31.22:3000/data/aerodynamics_workshop_1k.hdr')
   
    THREE.ColorManagement.legacyMode = false

    var kks=0
    var subtitleY=40

    useLayoutEffect(() => {

        

    }, []);  


    function cab(){

        console.log('call back')
        setcontenttextAlpha(1)
        setblockcontenttextAlpha(0)
        setloadfinish(1)
        props.loadpop(true)

    }


    function Scollcallback(e){

       props.callback([e[0],e[2]])
      
        
    }
 

    
   useEffect(() => {
   

    if(window.innerWidth>=800  ){

     setsubtxtleft(54)
     setendlefttext((window.innerWidth*.57))
    }else{

      setendlefttext((window.innerWidth-210)/2)
    } 
   

   },[scrollspeed])


   
 

  


    return(<div style={{width:'100%',height:'100vh'}} >
 
        <Canvas shadows  camera={{ zoom: 40,position: [0, 0, 5]}} gl={{ alpha: true, antialias: false, stencil: false, depth: false }} dpr={[1, 1.2]} >
       
 {/*
                   <Environment files="http://192.168.31.22:3000/data/industrial_workshop_foundry_1k.hdr" />
               <ambientLight intensity={0.4} />
            <spotLight intensity={.4} angle={0.3} penumbra={1} position={[10, 15, 10]} castShadow />
           
            
                  */}
           
        
        <Suspense fallback={<NewLoader callback={cab} />}>
 
             

            <ScrollControls  damping={0.1} pages={23} infinite={false} distance={1}    >
          
            <Items  callback={Scollcallback} />
            <Mdos Loadedin={loadfinish} />
              
   {/*
 <Bg />    
    
               <Model  url={Models[0].url}  texture={texture}  />   

                  */}
                  
                <Scroll html style={{ width: '100%',height:'100%',opacity:1 }} >
                   
                    <div style={{width:"100vw",position:"absolute",opacity:contenttextAlpha,transition:' all 1s'}}>
                    <div  className={'txt1'} style={{ position: 'absolute', top: '52vh', left: '10%' ,fontSize: '2em',color:'#fff',maxWidth:'380px',backgroundColor:'white',width:'1.8em',height:'.1em'}}> </div>
                    <h1  className={'txt1'} style={{ position: 'absolute', top: '56vh', left: '10%',fontSize: '3em', transform: `translate3d(0,-100%,0)`,color:'#fff' }}>Intro. </h1>
                        <h1  className={'txt2'} style={{ position: 'absolute', top: '60vh', left: '10%' ,fontSize: '1.5em',color:'#fff',maxWidth:'370px'}}>irabit is an independent artist driven by digital art and creative technology. </h1>

                       
                         
                        <img src={hostloca+'Assets/we.png'}  style={{ position: 'absolute', top: '132vh', left:  10-1+'%' ,fontSize: '1.8em',color:'#fff',width:'30vh',}} />
                        <h1  className={'txt1'} style={{ position: 'absolute', top: '151vh', left:  '10%',fontSize: '1.8em', transform: `translate3d(0,-100%,0)`,color:'#fff' }}>/ designers / coders  </h1>
                        <h1  className={'txt1'} style={{ position: 'absolute', top: '154vh', left:  '10%' ,fontSize: '1.8em',color:'#fff'}}>/ makers and creators.</h1>

                        <div  className={'txt1'} style={{ position: 'absolute', top: '230vh', left:  10+'%' ,fontSize: '2em',color:'#fff',maxWidth:'380px',backgroundColor:'white',width:'1.8em',height:'.1em'}}> </div>

                        <h1  className={'txt1'} style={{ position: 'absolute', top: '230vh', left:  10+'%' ,fontSize: '1.8em',color:'#fff',maxWidth:'380px',}}>We help to provide user experience accretion.</h1>

                       
                        <h1  className={'txt1'} style={{ position: 'absolute', top: '330vh', left:  10+'%' ,fontSize: '1.8em',color:'#fff',maxWidth:'380px',}}>Create unique visual expression and interactive experience.</h1>
                        <img src={hostloca+'Assets/d.png'}  style={{ position: 'absolute', top: 372+subtitleY+'vh', left:  subtxtleft+'%' ,fontSize: '1.8em',color:'#fff',width:'40vh',}} />
                        
 
                        <img src={hostloca+'Assets/v.png'}  style={{ position: 'absolute', top: 1080+subtitleY+'vh', left:  subtxtleft+'%' ,fontSize: '1.8em',color:'#fff',width:'48vh',}} />
                         
                        <h1  className={'txt1'} style={{ position: 'absolute', top: 1150+subtitleY+'vh', left:  subtxtleft+'%',fontSize: '3em',color:'#fff'}}>CGI Animated <br/>Short Film.</h1>

                        <img src={hostloca+'Assets/g.png'}  style={{ position: 'absolute', top: 1412+subtitleY+'vh', left:  subtxtleft+'%' ,fontSize: '1.8em',color:'#fff',width:'42vh',}} />
                        <h1  className={'txt1'} style={{ position: 'absolute', top: 1482+subtitleY+'vh', left:  subtxtleft+'%',fontSize: '3em',color:'#fff'}}>Lowpoly art.</h1>
                        <h1  className={'txt1'} style={{ position: 'absolute', top: 1490+subtitleY+'vh', left:  subtxtleft+'%',fontSize: '2em',color:'#fff'}}>& Game assets.</h1>

                        <h1  className={'txt1'} style={{ position: 'absolute', top: 1650+subtitleY+'vh', left:  subtxtleft+'%',fontSize: '2em',color:'#fff'}}>Independent Game<br/> develop.</h1>

                       
                        
                        <img src={hostloca+'Assets/d2.png'}  style={{ position: 'absolute', top: 1820+subtitleY+'vh', left:  subtxtleft+'%' ,fontSize: '1.8em',color:'#fff',width:'35vh',}} />
                        <h1  className={'txt1'} style={{ position: 'absolute', top: 1910+subtitleY+'vh', left:  subtxtleft+'%',fontSize: '2em',color:'#fff'}}>Input devices, webGL, <br/>video and audio real-<br/>time mixing.</h1>
                        <h1  className={'txt1'} style={{ position: 'absolute', top: 2060+subtitleY+'vh', left:  subtxtleft+3+'%',fontSize: '2.5em',color:'#fff'}}>Generated <br/>art.</h1>
                        
                          <div style={{width:"60vh",height:"260vh",position:"absolute",backgroundColor:'black',textAlign:'center',display:'flex',alignItems:'center',transform:' rotate(40deg)',left:subtxtleft+'%',top:'2120vh'}}>
                          </div>
                          <img src={hostloca+'Assets/irabitlogoico1.png'} style={{position: 'absolute', top: 2206+subtitleY+'vh', left:  endlefttext+88+'px' ,fontSize: '1.8em',color:'#fff',width:'50px',}} />

                        <h1  className={'txt3'} style={{ position: 'absolute', top: '2254vh', left:  endlefttext-20+'px',fontSize: '1.1em',color:'#fff',textAlign:'center'}}>
                           
                            Contact: develop@irabit.net<br/><br/>
                            © 2023 Copyright All Rights Reserved <br/><br/>
                            <a  className={'txt3'} style={{fontSize:'.7em'}} href="https://beian.miit.gov.cn/">粤ICP备11006497号-1</a> <br/>
                            </h1>

                        

                        
                   
                    </div>

                    <div style={{width:"100vw",height:'2000vh',position:"fixed",opacity:blockcontenttextAlpha,backgroundColor:'black',display:"flex",top:"0px",pointerEvents:"none",transition:' all 2s 1.4s'}}> </div>
                </Scroll>
            </ScrollControls>
              
            </Suspense>
                 

        

       

             
          
               <Environment  resolution={64} files={hostloca+"data/industrial_workshop_foundry_1k.hdr"} >


                
                  {/* 
                      <Stats />  
                <mesh scale={100}>
                <sphereGeometry args={[1, 64, 64]} />
                <LayerMaterial side={THREE.BackSide}>
                    <Base color="blue" alpha={1} mode="normal" />
                    <Depth colorA="#00ffff" colorB="#ff8f00" alpha={0.5} mode="normal" near={0} far={300} origin={[100, 100, 100]} />
                    <Noise mapping="local" type="cell" scale={.5} mode="softlight" />
                </LayerMaterial>
                </mesh>
                
                 */}
               
            </Environment>
          
                 {/* 
                   
                <OrbitControls autoRotate autoRotateSpeed={0.025} />
                
                 */}
              
        </Canvas>
       
        {/*
        <Loader
            containerStyles={{}} // Flex layout styles
            innerStyles={{}} // Inner container styles
            barStyles={{}} // Loading-bar styles
            dataStyles={{}} // Text styles
            dataInterpolation={(p) => `Loading ${p.toFixed(2)}%`} // Text
            initialState={(active) => active} // Initial black out state
        />
            */}
    </div>
    )
}


function ColumnMaker(props){
  
  const [ColumnState, setColumnState] = useState([[0.3,'white',1],[0.3,'white',1],[0.3,'white',1]]);

   var colarry=[.3,'white',1]
   var colarryMark=[.8,'transparent',1]

 
 

   useEffect(() => {
    var colset=[]

     
    
 
   
     
    colset[0]=colarry
    colset[1]=colarry
    colset[2]=colarry


    
    colset[props.col]=colarryMark
    setColumnState(colset)


   },[props.col])

 
  
   

  return(
    <div style={{width:"5vh",height:"13vh",position:"fixed",right:'2vw',top:'35vh',zIndex:101,textAlign:'center',alignItems:'center',display:'flex',flexDirection:'column',transition:'all 1s',opacity:props.show}}>
                  
                  <div className={'Columnmaker'} style={{width:ColumnState[0][0]+'vh',height:ColumnState[0][0]+'vh',backgroundColor:ColumnState[0][1],borderWidth:ColumnState[0][2]+'px'}}>  </div>
                  <div className={'Columnmaker'} style={{width:ColumnState[1][0]+'vh',height:ColumnState[1][0]+'vh',backgroundColor:ColumnState[1][1],borderWidth:ColumnState[1][2]+'px'}}>  </div>
                  <div className={'Columnmaker'} style={{width:ColumnState[2][0]+'vh',height:ColumnState[2][0]+'vh',backgroundColor:ColumnState[2][1],borderWidth:ColumnState[2][2]+'px'}}>  </div>
              </div>
  )
}

/**
 * main view
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function Home(props){
  const [Ncol, setcol] = useState(0);
  const [showalpha, setshowalpha] = useState(0);
  const [scrollP, setscrollP] = useState(0.0);
  const videoEl = useRef(null);
  const [artcodelab, setartcodelab] = useState(0);

  var kks=0

  function Scenecallback(e){
    setcol(e[0])
    var mobledi=0
     kks=Math.floor(e[1] * 10) / 10*90
     if(window.innerWidth<600){

      mobledi=20
     }

    //console.log(scrollP)
    setscrollP(58-kks-mobledi)
    
  }
 

  function loadfinish(e){

    setshowalpha(1)
    
  }



 
 

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch(error => {
        console.error("Error attempting to play", error);
      });
  };


   //异步判断index页面微信接口是否加载完毕

          useEffect(() => {
            const handleCustomEvent = (event) => {
              console.log('Received wx event:', event.detail);
              // alert( event.detail)
             // alert(soundstate)
             const video = videoEl.current;
             video.play()         
              attemptPlay();
       
 
              
              // 这里可以调用组件内的任何方法
            };
        
            window.addEventListener('myCustomEvent', handleCustomEvent);
        
            return () => {
              window.removeEventListener('myCustomEvent', handleCustomEvent);
            };
          }, []);






  const handleScroll = () => {
    const position = window.scrollY;
    console.log(position)
  }



  useEffect(() => {
    attemptPlay();

    

   
    
    if(window.innerWidth>=800  ){

      setartcodelab(1)
      
     } 


    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
        window.removeEventListener('scroll', handleScroll);
    };

    
  }, []);


  

    return(
    <div >
  <div style={{width:"100vh",height:"100vh",position:"fixed",backgroundColor:'black',textAlign:'center',display:'flex',alignItems:'center'}}>
              
             
          
   
    
  

          <video
          style={{position:"fixed",display: 'block',height:'150vh',width:'140vw',right:'-65vw',top:'-10vh',transform:' rotate(30deg)',opacity:showalpha}}
          playsInline
          loop
          muted
          
          alt="All the devices"
          src="https://cdn.irabit.com/media/v20240621.mp4"
          ref={videoEl}
        />
      
      <img src={hostloca+'Assets/a.png'}  style={{ position: 'fixed', top: '56vh', right:  10+'%' ,fontSize: '1.8em',color:'#fff',width:'20vh',opacity:artcodelab,transitionTimingFunction:'ease-out',transition:'all .8s'}} />

          <div style={{width:"200vh",height:"200vh",position:"fixed",backgroundColor:'black',textAlign:'center',display:'flex',alignItems:'center',transform:' rotate(30deg)',right:scrollP+'vh',top:'-70vh',transitionTimingFunction:'ease-out',transition:'all .8s'}}>
             </div>
             
             
             </div>

             

             <div style={{width:"6vh",height:"3vh",position:"fixed",paddingTop:'2vh',paddingLeft:'2.5vh',zIndex:100,opacity:showalpha,transition:'all 1s'}}>
              
              <img src={hostloca+'Assets/logo.png'} width={'100%'} />
             </div>
             
              <ColumnMaker col={Ncol} show={showalpha}/>
             <Scene callback={Scenecallback} loadpop={loadfinish}/>

 
       
    </div>
    )





}


export default Home